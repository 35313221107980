import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import { BiPhoneCall } from 'react-icons/bi'
import { BsCalendar4Range, BsClockHistory } from 'react-icons/bs'
import Navmenu from './NavMenu'



const HeaderSeven = () => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='w-full flex justify-center items-center flex-col bg-white relative top-0 z-10'>
            <div className='w-[90%] flex md:flex-row flex-row-reverse space-x-4 '>
                <div className='md:w-[25%] w-[100%] my-auto'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[100%] md:w-[90%] mx-auto pt-3 pb-5'
                    />
                </div>
                <div className="md:w-full w-[20%] hidden  md:flex flex-col justify-center">
                    <div className='md:flex mt-5 hidden'>
                        <div className='w-[55%] lg:w-[75%]'>
                            <div className='grid grid-cols-1 lg:grid-cols-3'>

                                <div className='flex  text-black'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BiPhoneCall color='black' fontSize={35} />
                                    </div>
                                    <div>
                                        <a
                                            href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                        >
                                            <p className='-mb-3'>Phone Number</p>
                                            <h5 className='text-[21px]'>
                                                {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                            </h5>
                                        </a>
                                    </div>
                                </div>

                                <div className='hidden text-black  lg:flex'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BsCalendar4Range color='black' fontSize={35} />
                                    </div>
                                    <div>
                                        <p className='-mb-3'>Workdays</p>
                                        <h5 className='text-[21px]'>
                                            {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                        </h5>
                                    </div>
                                </div>

                                <div className='hidden lg:flex justify-end text-black'>
                                    <div className='w-[15%] self-center mr-3'>
                                        <BsClockHistory color='black' fontSize={35} />
                                    </div>
                                    <div>
                                        <p className='-mb-3'>Work Hours</p>
                                        <h5 className='text-[21px]'>
                                            {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-[45%] lg:w-[25%] text-end border-l ml-0 lg:ml-7'>
                            <ButtonContent />
                        </div>
                    </div>

                </div>

            </div>
            <div className='w-full h-10 z-[5] flex items-center'>
                <div className='w-[80%] bg1 py-3 mx-auto corte flex justify-center -mb-10'>
                    <nav className='flex'>
                        <Navmenu />
                    </nav>
                </div>
            </div>

        </header>
    )
}

export default HeaderSeven